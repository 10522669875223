import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  images: ImageProps[]
}

export const OfferGallery = memo(function OfferGallery({ images }: Props) {
  if (!images) {
    return null
  }

  return (
    <Container row tag="section" wrap>
      {images.map((item, index) => (
        <Image key={index}>
          <LazyLoadImage effect="blur" {...item} />
        </Image>
      ))}
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 2.8125rem -3.0625rem 5.875rem 0;
  padding: 0 5.833vw;

  @media (max-width: 1023px) {
    margin: 2.25rem -0.75rem 3.75rem 0;
  }
`

const Image = styled.div`
  width: calc(50% - 3.0625rem);
  margin: 3.0625rem 3.0625rem 0 0;
  padding-bottom: 34%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: calc(50% - 0.75rem);
    margin: 0.75rem 0.75rem 0 0;
  }
`
