import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import React, { memo } from 'react'

export interface Props {
  list?: string[]
  title?: string
}

export const Facilities = memo(function Facilities({ list, title }: Props) {
  if (!list) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      <FadeInUp>
        <List>
          {list.map((item, index) => (
            <Item key={index}>
              {
                // @ts-ignore
                item.label
              }
            </Item>
          ))}
        </List>
      </FadeInUp>
    </Container>
  )
})

const Container = styled.div`
  margin-top: 4.625rem;
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.25rem;
  text-transform: uppercase;
`

const List = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  margin-top: 1.875rem;
  padding-left: 1.875rem;
  position: relative;
  &:before {
    content: '';
    width: 0.625rem;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Item = styled.div`
  margin-top: 0.375rem;
  &:first-of-type {
    margin-top: 0;
  }
`
